var Cart = function () {
    this._productModal = $('#product-modal')
    this._body = $("body");
    this._type = 'full';
    this._cartContainer = $(".full-cart-placeholder");
    this.initialize();
};

Cart.prototype = {
    _productModal: null,
    _body: null,
    _type: null,
    _cartContainer: null,
    initialize: function () {

        var self = this;
        $.get('/cart/cart?type=' + self._type, {}, function (html) {
            self.openWithHTML(html);
        });

        self._body.on('click', '.single-result', function (e) {
            e.preventDefault();
            self.openModal($(this));
            $('.results').find(".list-loader").show();
            $(this).find(".list-loader").show();
        });

    },
    filterColors: function (color) {
        this._productModal.find('.img .productColor').hide();
        this._productModal.find('.img .productColor-' + color).show();

        this._productModal.find('.single-element').hide();
        this._productModal.find('.single-element.color-' + color).show();

        this._productModal.find('.nav-item button').removeClass('active');
        this._productModal.find('.nav-item.filter-' + color + ' button').addClass('active');
    },
    openModal: function (button) {
        var self = this;
        $.get('/product/details/' + button.data('id'), {}, function (html) {
            self._productModal.html(html);
            self._productModal.removeClass('d-none');
            var firstColor = self._productModal.find('.product-cluster #colors .nav-item').first();
            if (firstColor.length > 0) {
                self.filterColors(firstColor.data('color'));
            }

            $('.results').find(".list-loader").hide();
            $('.single-result').find(".list-loader").hide();

            self._productModal.find('.quantity input').off('blur').on('blur', function (e) {
                e.preventDefault();
                var minOrder = $(this).closest(".single-element").data("minorder");
                var value = $(this).val();
                value = parseInt(Math.ceil(value / minOrder) * minOrder);
                $(this).val(value);

                document.querySelectorAll('.order-input-qty').forEach(function (inputElement) {
                    inputElement.addEventListener('input', function (event) {
                        var sanitizedValue = event.target.value.replace(/[^0-9]/g, '');
                        event.target.value = sanitizedValue;
                    });
                });
            });

            self._productModal.find('.cancel').off('click').on('click', function (e) {
                e.preventDefault();
                self.hideModal(false);
            });

            self._productModal.off('click').on('click', function (e) {
                e.preventDefault();
                if ($(e.target).is(self._productModal)) {
                    self.hideModal(false);
                }
            });

            self._productModal.find('#colors .nav-item').off('click').on('click', function (e) {
                e.preventDefault();
                self.filterColors($(this).data('color'));
            });

            self._productModal.find('.add-to-order').off('click').on('click', function (e) {
                e.preventDefault();

                var cartData = [];
                self._productModal.find('.single-element').each(function () {
                    var qty = $(this).find('.quantity input').val();
                    var options = [];
                    $(this).find(".product-options").each(function () {
                        if ($(this).val()) {
                            options.push($(this).val());
                        }
                    })
                    if (qty != 0) {
                        cartData.push({ id: $(this).data('id'), qty: qty, options: options });
                    }
                });
                if (cartData.length > 0) {
                    $.post('/cart/addBulk?type=' + self._type, {
                        data: cartData,
                        replace: true,
                    }, function (html) {
                        self.hideModal(true);
                        $('.alert.cart').addClass('show');

                        getStatus();

                        var cartCount = $(html);
                        $(".header .new-order .count-order").show().html(cartCount.data("count"));

                        setTimeout(function () {
                            $(".alert.cart").removeClass('show');
                        }, 6000);
                    });

                    return false;
                }
            });
        });
    },
    hideModal: function (reloadCart) {
        var self = this;
        this._productModal.addClass('d-none');
        if (reloadCart) {
            $.get('/cart/cart?type=' + self._type, {}, function (html) {
                self.openWithHTML(html);
            });
        }
    },
    openWithHTML: function (html) {
        var self = this;

        self._cartContainer.html(html).addClass('show');

        $(".list-loader").hide();

        self._cartContainer.find('.btn-plus').on('click', function () {
            var product = $(this).parents('.product');
            var qty = product.find('.qty');
            var minOrder = $(this).parents(".product").find(".qty").data("minorder");

            qty.val(parseInt(qty.val()) + minOrder).trigger('blur');

            return false;
        });

        self._cartContainer.find('.btn-minus').on('click', function () {
            var product = $(this).parents('.product');
            var qty = product.find('.qty');
            var minOrder = $(this).parents(".product").find(".qty").data("minorder");

            qty.val(parseInt(qty.val() - minOrder)).trigger('blur');

            return false;
        });

        self._cartContainer.find('.qty').on('blur', function () {
            var product = $(this).parents('.product');

            var options = product.data('options') == '' ? [] : product.data('options').split(';');

            self.change('qty', {
                id: product.data('id'),
                qty: product.find('.qty').val(),
                options: options
            }, $(this));

            return false;
        });


        self._cartContainer.find('.remove-button').on('click', function () {
            var product = $(this).parents('.product');

            var options = product.data('options') == '' ? [] : product.data('options').split(';');

            self.change('remove', {
                id: product.data('id'),
                options: options
            }, $(this));

            return false;
        });

        self._cartContainer.find('.edit-button').on('click', function () {
            var product = $(this).parents('.product');

            self.openModal(product);

            return false;
        });
    },
    change: function (action, data, element) {
        var self = this;

        if (self._changeRequest != null) {
            self._changeRequest.abort();
        }
        if (self._changeTimeout != null) {
            clearTimeout(self._changeTimeout);
        }

        self._changeTimeout = setTimeout(function () {

            element.parents('.product').find(".quantity.input-group").append('<div class="list-loader"><div class="inner"></div></div>');

            self._cartContainer.find('.cart-overlay').show();

            self._changeRequest = $.post('/cart/' + action + '?type=' + self._type, data, function (html) {
                self.openWithHTML(html);
            });
        }, 600);
    }
};
new Cart();

getStatus();

function getStatus() {
    if (!checkPublicClass($("body"))) {
        $.get('/cart/status?onlyCount', function (response) {
            var data = JSON.parse(response);

            if (data.cart > 0) {
                $(".count-order").html(data.cart).show();
            } else {
                $(".count-order").hide();
            }
        });
    }
}
